import React from "react";
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

import { Profile } from ".";

import { loggedUserName } from "../services/app";


export class TopMenu extends React.Component {
    render() {
        var user = loggedUserName();

        return <>
            <div className="logo">INKOsmart</div>
            <Profile />

            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']} >
                <Menu.Item key="0" style={{ marginLeft: 80 }}><Link to="/">Home</Link></Menu.Item>
                {user &&
                    <Menu.Item key="4"><Link to="/documentation">Documentation</Link></Menu.Item>
                }
            </Menu>
        </>;
    }
}