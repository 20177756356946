import {Input, Menu, Modal} from 'antd';
import React from 'react';
import {
    CalendarOutlined,
    CheckCircleOutlined,
    DiffOutlined,
    InfoCircleOutlined,
    CodeSandboxOutlined,
    BgColorsOutlined
} from '@ant-design/icons';
import {Link} from 'react-router-dom';

import {SearchModal} from '.';
import {isLoggedIn, productsAllowed} from '../services';
import {Routes} from '../constants';

const {Search} = Input;

export class Sider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allOrders: [],
            form: '',
            isModalVisible: false,
            menuLiveOrders: false,
            menuNew: false,
            menuProduction: false,
            menuShipping: false,
            menuDone: false,
            menuUsers: false,
            InkoprintBE: false,
            InkoprintES: false,
            AdmInkoprintBE: false,
            AdmInkoprintES: false,
            defaultOpenKeys: ['inkoprint.be', 'inkoprint.es']
        }
    }


    //FUNCTIONS FOR THIS MODAL
    showModal = () => {
        this.setState({
            isModalVisible: true
        });
    };

    handleOk = () => {
        this.setState({
            isModalVisible: false
        });
    };
    handleCancel = () => {
        this.setState({
            isModalVisible: false
        });
    };

    componentDidMount = () => {
        this.checkPermissions()
    }

    checkPermissions = () => {
        console.log({productsAllowed: productsAllowed()})
        productsAllowed().forEach((permission) => {
            switch (permission) {
                case 'all':
                    this.setState(prev => ({
                        ...prev,
                        menuLiveOrders: true,
                        menuNew: true,
                        menuProduction: true,
                        menuShipping: true,
                        menuDone: true,
                        menuUsers: true,
                        InkoprintBE: true,
                        InkoprintES: true,
                        AdmInkoprintBE: true,
                        AdmInkoprintES: true,
                    }));
                    break;
                case 'New':
                    this.setState({menuNew: true});
                    break;
                case 'inProduction':
                    this.setState({menuProduction: true});
                    break;
                case 'Shipping':
                    this.setState({menuShipping: true});
                    break;
                case 'Done':
                    this.setState({menuDone: true});
                    break;
                case 'InkoprintBE':
                    this.setState(prev => ({
                        ...prev,
                        InkoprintBE: true,
                    }))
                    break;
                case 'InkoprintES':
                    this.setState(prev => ({...prev, InkoprintES: true}))
                    break;
                case 'AdmInkoprintBE':
                    this.setState(prev => ({
                        ...prev,
                        AdmInkoprintBE: true,
                    }))
                    break;
                case 'AdmInkoprintES':
                    this.setState(prev => ({
                        ...prev,
                        AdmInkoprintES: true,
                    }))
                    break;
                default:
                    break;
            }
        })
    }

    //FUNCTION ON CHANGE
    handleKeyPress = (event) => {
        event.preventDefault();
        this.setState({form: event.target.value});
    }

    render() {
        const defaultOpenKeys = this.state.defaultOpenKeys
        return (
            <>
                {defaultOpenKeys.length && <Menu style={{width: '256px', minWidth: '256px', maxWidth: '256px'}}
                                                 onClick={this.handleClick}
                                                 defaultOpenKeys={defaultOpenKeys}
                                                 mode="inline"
                >
                    <Search placeholder='Search order...'
                            autoComplete='off'
                            type="text"
                            key="26"
                            onChange={this.handleKeyPress}
                            onSearch={this.showModal}
                            style={{width: '100%', padding: '20px 20px'}}
                            disabled={!isLoggedIn()}
                    />
                    {this.state.InkoprintBE && <Menu.SubMenu key="inkoprint.be" title="BE - INKOprint.be">
                        <Menu.Item key="20" icon={<CalendarOutlined/>} disabled={!isLoggedIn()}>
                            <Link to={Routes.Planning}>Planning</Link>
                        </Menu.Item>
                        <Menu.ItemGroup key="g1" title="STEPS - ORDERS">
                            <Menu.Item key="1" icon={<InfoCircleOutlined/>}
                                       disabled={!this.state.AdmInkoprintBE || !isLoggedIn()}>
                                <Link to={Routes.LiveOrders}>Live orders</Link>
                            </Menu.Item>
                            <Menu.Item key="2" icon={<DiffOutlined/>} disabled={!this.state.menuNew || !isLoggedIn()}>
                                <Link to={Routes.New}>New</Link>
                            </Menu.Item>
                            <Menu.Item key="3" icon={<BgColorsOutlined/>}
                                       disabled={!this.state.menuProduction || !isLoggedIn()}>
                                <Link to={Routes.InProduction}>In Production</Link>
                            </Menu.Item>
                            <Menu.Item key="4" icon={<CodeSandboxOutlined/>}
                                       disabled={!this.state.menuShipping || !isLoggedIn()}>
                                <Link to={Routes.Shipping}>Shipping</Link>
                            </Menu.Item>
                            <Menu.Item key="5" icon={<CheckCircleOutlined/>}
                                       disabled={!this.state.menuDone || !isLoggedIn()}>
                                <Link to={Routes.Done}>Done</Link>
                            </Menu.Item>
                            {/*<Menu.Item key="6" icon={<CheckCircleOutlined/>}*/}
                            {/*           disabled={!this.state.menuUsers || !isLoggedIn()}>*/}
                            {/*    <Link to={Routes.Users}>Users</Link>*/}
                            {/*</Menu.Item>*/}
                        </Menu.ItemGroup>
                    </Menu.SubMenu>}
                    {this.state.InkoprintES && <Menu.SubMenu key="inkoprint.es" title="ES - INKOprint.es">
                        <Menu.Item key="20es" icon={<CalendarOutlined/>} disabled={!isLoggedIn()}>
                            <Link to={Routes.PlanningES}>Planning - ES</Link>
                        </Menu.Item>
                        <Menu.ItemGroup key="ges" title="STEPS - ORDERS">
                            <Menu.Item key="1es" icon={<InfoCircleOutlined/>}
                                       disabled={!this.state.AdmInkoprintES || !isLoggedIn()}>
                                <Link to={Routes.LiveOrdersES}>Live orders - ES</Link>
                            </Menu.Item>
                            <Menu.Item key="2es" icon={<DiffOutlined/>} disabled={!this.state.menuNew || !isLoggedIn()}>
                                <Link to={Routes.NewES}>New - ES</Link>
                            </Menu.Item>
                            <Menu.Item key="3es" icon={<BgColorsOutlined/>}
                                       disabled={!this.state.menuProduction || !isLoggedIn()}>
                                <Link to={Routes.InProductionES}>In Production - ES</Link>
                            </Menu.Item>
                            <Menu.Item key="4es" icon={<CodeSandboxOutlined/>}
                                       disabled={!this.state.menuShipping || !isLoggedIn()}>
                                <Link to={Routes.ShippingES}>Shipping - ES</Link>
                            </Menu.Item>
                            <Menu.Item key="5es" icon={<CheckCircleOutlined/>}
                                       disabled={!this.state.menuDone || !isLoggedIn()}>
                                <Link to={Routes.DoneES}>Done - ES</Link>
                            </Menu.Item>
                            {/*<Menu.Item key="6es" icon={<CheckCircleOutlined/>}*/}
                            {/*           disabled={!this.state.menuUsers || !isLoggedIn()}>*/}
                            {/*    <Link to={Routes.UsersES}>Users - ES</Link>*/}
                            {/*</Menu.Item>*/}
                        </Menu.ItemGroup>
                    </Menu.SubMenu>}
                </Menu>}

                <Modal title="Search order" visible={this.state.isModalVisible} destroyOnClose={true}
                       onOk={this.handleOk} onCancel={this.handleCancel} footer={null} centered width={1400}>

                    <SearchModal form={this.state.form} className='border' centered term={this.state.form}
                                 hideModal={this.handleCancel}/>
                </Modal>
            </>
        );
    }
}
