import React from "react";
import {withRouter} from "react-router-dom";
import {Row, Col, Input, Timeline, Divider, Card, Typography, Tabs, Button, Spin} from "antd";

import {ChangeStatusButton} from "../components";
import {DownloadableFiles, ShowCalculations} from "../modules";
import {getCountryCode, getOrderById} from "../services";

const {TabPane} = Tabs;
const {Title} = Typography;
const {TextArea} = Input;

class ToBeProducedComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            strapiId: null,
            order: [],
            delivery_address: {},
            sender_address: {},
            calculation: [],
            description: {},
            uploads: [],
            orderID: null,
            readerror: [],
            calculatedPrice: null,
            renderAlertMessage: false,
            message: "",
            buttonRejected: true,
            renderAlertSuccess: false,
            loading: true,
        };
    }

    componentDidMount = async () => {
        const id = this.props.match.params.id;
        const country = this.props.match.params.country === 'ES' ? 'ES' : 'BE';
        this.setState({orderID: id});

        try {
            const response = await getOrderById(id, country);
            // debugger
            const order = response.data[0];
            this.setState({
                strapiId: order.id,
                order: order,
                delivery_address: order.receiver,
                sender_address: order.sender,
                calculation: order.fullorder,
                description: order.description_long,
                uploads: order.uploads,
                loading: false,
            });

            // SET CALCULATIONS IF MORE THAN ONE
            if (typeof order.calculation["aantal vel papier"].eerste === "object") {
                this.setState({calculation: order.calculation["aantal vel papier"],});
            } else {
                this.setState({calculation: order.calculation});
            }
        } catch (error) {
            this.setState({readerror: error});
        }
    }

    nextStatus = () => {
        const country = this.props.match.params.country === 'ES' ? 'ES' : 'BE';
        const data = {
            Status: "InProduction",
            order_id: this.state.orderID,
            order_status: "IS_in_production",
            Site: getCountryCode(country),
            buttonText: "to production",
            strapiID: this.state.strapiId,
            method: "put",
            nextStep: "production",
            type: "primary"
        };
        return data;
    };

    rejectStatus = () => {
        const country = this.props.match.params.country === 'ES' ? 'ES' : 'BE';
        const data = {
            Status: "Rejected",
            order_id: this.state.orderID,
            order_status: "IS_Rejected",
            trigger: "rejected",
            order_message: this.state.message,
            Site: getCountryCode(country),
            buttonText: "Reject order",
            strapiID: this.state.strapiId,
            method: "put",
            nextStep: "accepted",
            type: "danger"
        };
        return data;
    };

    render() {
        const {orderID, order, loading, sender_address, delivery_address, calculation, description,} = this.state;

        if (loading) return <Spin/>;

        if (!order) return <></>;

        var today = new Date();
        var parts = order.deliverydate.split("-");
        var lastDay = new Date(parts[2], parts[1] - 1, parts[0]);
        var remainingDays = Math.ceil((lastDay - today) / (1000 * 3600 * 24));

        return (
            <>
                <Row>
                    <Col flex={10}>
                        <Title>
                            {orderID} - {order.description}
                        </Title>
                    </Col>

                    <Col flex={1}>
                        <ChangeStatusButton statusData={this.nextStatus()}/>
                    </Col>
                </Row>

                <Tabs defaultActiveKey="1" onChange={this.callBack}>
                    <TabPane tab="Summary" key="1">
                        <Title level={2}>Summary</Title>

                        <Row>
                            <Col flex={1}>
                                <Divider orientation="left">Date information</Divider>
                                <br/>
                                <Timeline>
                                    <Timeline.Item>
                                        Order date was {order.orderdate}
                                    </Timeline.Item>
                                    <Timeline.Item>
                                        Shipping date {order.deliverydate}
                                    </Timeline.Item>
                                </Timeline>
                                <br/>

                                <Divider orientation="left">Price information</Divider>

                                <p>
                                    <b>Price: </b>
                                    {order.price}
                                </p>

                                <br/>
                                <Divider orientation="left">Shipping information</Divider>
                                <p>
                                    <b>Remaining days to complete: </b>
                                    {remainingDays}
                                </p>
                            </Col>

                            <Col flex={1}>
                                <Card title="Product information">
                                    <h3>{order.amount}x</h3>

                                    {Object.keys(description ? description : "").map(
                                        (keyName, i) => (
                                            <li key={i}>
                        <span className="input-label">
                          <b>{keyName}</b> {description[keyName]}
                        </span>
                                            </li>
                                        )
                                    )}
                                </Card>
                                <br/> <br/>
                                <Card title="Reject the order">
                                    <p>
                                        <b>Order rejected because:</b> {order.reason_rejected}
                                    </p>
                                    <TextArea
                                        rows={4}
                                        allowClear
                                        showCount
                                        onChange={(event) => {
                                            const message = event.target.value;
                                            if (message.length > 9) {
                                                this.setState({
                                                    message: message,
                                                    buttonRejected: false
                                                });
                                            } else {
                                                this.setState({buttonRejected: true})
                                            }
                                        }}
                                    />

                                    {this.state.buttonRejected ?
                                        <Button disabled danger> Rejected order</Button>
                                        :
                                        <ChangeStatusButton statusData={this.rejectStatus()}/>
                                    }
                                </Card>
                                <br/>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Print information" key="2">
                        <Title level={2}>Print information</Title>
                        <Row>
                            <Col flex={1}>
                                <DownloadableFiles order={order}/>
                                <br/>
                                <Card title="Calculation outcome">
                                    <ShowCalculations calc={calculation}/>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Delivery" key="3">
                        <Title level={2}>Delivery information</Title>

                        <Row>
                            <Col flex={1}>
                                <Card title="Delivery address">
                                    {Object.keys(delivery_address ? delivery_address : "").map(
                                        (keyName, i) => (
                                            <li key={i}>
                        <span className="input-label">
                          <b>{keyName}</b> {delivery_address[keyName]}
                        </span>
                                            </li>
                                        )
                                    )}
                                </Card>
                            </Col>
                            <Col flex={1}>
                                <Card title="Sender address">
                                    {Object.keys(sender_address ? sender_address : "").map(
                                        (keyName, i) => (
                                            <li className="travelcompany-input" key={i}>
                        <span className="input-label">
                          <b>{keyName}</b> {sender_address[keyName]}
                        </span>
                                            </li>
                                        )
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>
            </>
        );
    }
}

export const ToBeProduced = withRouter(ToBeProducedComponent);
