import Cookies from "universal-cookie";

import {dbPost, getSuppliers, inkoprintPost} from ".";
import {navigate} from "./app";
import {Routes} from "../constants";

const cookies = new Cookies();

const clearAllCookies = () => {
    cookies.remove('user');
    cookies.remove('JWT');
    cookies.remove('productsAllowed');
    cookies.remove('email');
    cookies.remove('TOKENinko');
    cookies.remove('site');
    cookies.remove('TOKERinkoES')
    cookies.remove('supplier_id')
}

export function logOut() {
    clearAllCookies();
    navigate(Routes.Login, true);
}

export async function logIn(creds) {
    let jwtStrapi;
    let jwtInko;
    let response;
    let iSauthBE = false;
    let iSauthES = false;

    // Login to DB
    try {
        response = await dbPost('auth/local', JSON.stringify(creds));

        jwtStrapi = 'Bearer ' + response.data.jwt;
        cookies.set('JWT', jwtStrapi, {path: '/'});
    } catch (error) {
        throw new Error("Incorrect username or password! Please try again. Please make sure that user exists on db.inskosmart.com");
    }

    // Search for Supplier
    try {
        const suppliers = await getSuppliers();
        const supplier = suppliers.data.filter(x => x.email && x.email === creds.identifier)[0];

        if (!supplier) throw Error("1");

        //Check permissions
        supplier.productsAllowed.forEach(permission => {
            if (permission === 'InkoprintBE') iSauthBE = true;
            if (permission === 'InkoprintES') iSauthES = true;
            if (permission === 'all') {
                iSauthBE = true;
                iSauthES = true;
            }
        })
        cookies.set('user', supplier.name, {path: '/'});
        cookies.set('productsAllowed', supplier.productsAllowed, {path: '/'});
        cookies.set('email', supplier.email, {path: '/'});
        cookies.set('site', supplier.Site, {path: '/'})
        cookies.set('supplier_id', supplier.id, {path: '/'})
    } catch (error) {
        clearAllCookies();
        if(error.message === '1')
            throw new Error("Please add this email to a list of suppliers on db.inkosmart.com")
        throw new Error("System error! Please contact administrator.");
    }

    // Login to Inkoprint
    if (iSauthBE) {
        try {
            response = await inkoprintPost('auth', JSON.stringify(creds));
            jwtInko = 'Bearer ' + response.data.access_token;
            cookies.set('TOKENinko', jwtInko, {path: '/'});
        } catch (error) {
            clearAllCookies();
            throw new Error("Failed to connect to inkoprint API! Please check access to inkoprint.be API.");
        }
    }

    if (iSauthES) {
        try {
            response = await inkoprintPost('auth', JSON.stringify(creds), 'ES');
            const jwtInkoES = 'Bearer ' + response.data.access_token;
            cookies.set('TOKENinkoES', jwtInkoES, {path: '/'});
        } catch (error) {
            clearAllCookies();
            throw new Error("Failed to connect to inkoprint ES API! Please check access to inkoprint.es API.");
        }
    }
}