import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Select, Timeline, Divider, Card, Typography, Empty, Tabs, Button, } from "antd";
import TextArea from "antd/lib/input/TextArea";

import { ChangeStatusButton } from "../components";
import { DownloadableFiles, ShowCalculations } from "../modules";
import { getSuppliers, getCountryCode, inkoprintPost } from "../services";

const { TabPane } = Tabs;
const { Option } = Select;
const { Title } = Typography;

function updateState(newSupplier) {
  this.setState({ newSupplier });
}

class CheckingComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newSupplier: "No supplier",
      order: [],
      delivery_address: {},
      sender_address: {},
      calculation: [],
      description: {},
      uploads: [],
      orderID: null,
      mainOrderID: null,
      readerror: [],
      supplier: null,
      supplierlist: [],
      calculatedPrice: null,
      reason_rejected: "",
      renderAlertMessage: false,
      renderAlertSuccess: false,
      buttonRejected: true
    };
    this.updateState = updateState.bind(this);
  }

  componentDidMount = async () => {
    const id = this.props.match.params.id;
    const country = this.props.match.params.country === 'ES'? 'ES': 'BE';
    this.setState({ orderID: id });

    var newID = parseInt(id);
    var data = { order_id: newID };

    try {
      let response = await inkoprintPost('getOrders', data, country);
      var hasUploads = {};
      response.data[0].uploads === undefined ? (hasUploads = { "no uploads": null }) : (hasUploads = response.data[0].uploads);

      this.setState({
        mainOrderID: response.data[0]?.main_order_id,
        order: response.data[0],
        delivery_address: response.data[0]?.delivery_address,
        sender_address: response.data[0].sender_address,
        calculation: response.data[0].calculation,
        description: response.data[0].description_long,
        uploads: hasUploads,
        supplier: response.data[0].inkosmart_supplier_name,
      });

      if (this.state.supplier === null) {
        response = await getSuppliers();
        this.setState({ supplierlist: response.data });
      }
      else {
        this.setState({ newSupplier: this.state.supplier });
      }

      // SET CALCULATIONS IF MORE THAN ONE
      if (typeof response.data[0].calculation["aantal vel papier"].eerste === "object") {
        this.setState({ calculation: response.data[0].calculation["aantal vel papier"] });
      }
      else {
        this.setState({ calculation: response.data[0].calculation });
      }
    }
    catch (error) {
      this.setState({ readerror: error });
    }
  }

  AcceptedOrder = (order, calculation, sender_address, delivery_address, orderdate, orderdeliverydate) => {
    console.log(order.amount);
    const country = this.props.match.params.country === 'ES'? 'ES': 'BE';
    var objPushOrder = {
      reference: JSON.stringify(order.order_id),
      main_order_id: JSON.stringify(order.main_order_id),
      Status: "Accepted",
      portal: "inko",
      amount: JSON.stringify(order.amount),
      supplier_name: this.state.newSupplier,
      description: order.description,
      fullorder: calculation,
      orderdate: orderdate,
      deliverydate: orderdeliverydate,
      uploads: order.uploads,
      description_long: order.description_long,
      jobsheet: order.jobsheet,
      price: order.price,
      preferred_shipping_method: order?.shipping_method,
      sender: {
        contact: sender_address?.name,
        houseNo: sender_address?.house_number,
        addressLine1: sender_address?.address,
        addressLine2: null,
        city: sender_address?.city,
        name: null,
        addressLine3: null,
        zipCode: sender_address?.zip_code,
        state: null,
        country: sender_address?.country,
        accountNumber: null,
        type: null,
        telNo: null,
        email: sender_address?.email,
      },
      receiver: {
        houseNo: delivery_address?.house_number,
        shipmentLineId: null,
        addressLine1: delivery_address?.address,
        addressLine2: null,
        city: delivery_address?.city,
        name: delivery_address?.name,
        addressLine3: null,
        zipCode: delivery_address?.zip_code,
        state: null,
        lineNo: null,
        airwaybillNumber: null,
        country: delivery_address?.country,
        accountNumber: null,
        type: null,
        telNo: null,
        email: delivery_address?.email,
        contact: delivery_address?.contact,
      },
    };
    //console.log(objPushOrder);

    const data = {
      Status: "Accepted",
      order_id: this.state.orderID,
      order_status: "IS_accepted",
      trigger: "acceptedOrder",
      Site: getCountryCode(country),
      buttonText: "Accept order",
      strapiID: this.state.strapiId,
      method: "post",
      nextStep: "accepted",
      type: "primary",
      objPushOrder: objPushOrder
    };
    return data;
  };

  ConvertDate(value) {
    let date = value.toString();
    //console.log(value);
    let year = date.slice(0, 4);
    let month = date.slice(4, 6);
    let day = date.slice(6, 8);
    return day + "-" + month + "-" + year;
  }

  RejectStatus = () => {
    const country = this.props.match.params.country === 'ES'? 'ES': 'BE';
    const data = {
      Status: "Rejected",
      order_id: this.state.orderID,
      order_status: "IS_Rejected",
      trigger: "rejectedOrderChecking",
      order_message: this.state.message,
      Site: getCountryCode(country),
      buttonText: "Reject order",
      strapiID: this.state.strapiId,
      method: "put",
      nextStep: "accepted",
      type: "danger"
    };
    return data;
  };

  render() {
    const { order, orderID, readerror, sender_address, delivery_address, calculation, description, mainOrderID } = this.state;

    if (order.description !== undefined) {
      return (
        <>
          <Title>
            {orderID} ({mainOrderID}) - {order.description}
          </Title>

          <Tabs defaultActiveKey="1" onChange={this.callBack}>
            <TabPane tab="Summary" key="1">
              <Title level={2}>Summary</Title>
              <Row>
                <Col flex={1}>
                  <Divider orientation="left">Date information</Divider>
                  <br />
                  <Timeline>
                    <Timeline.Item>
                      Order date was {this.ConvertDate(order.order_date)}
                    </Timeline.Item>
                    <Timeline.Item>
                      Shipping date {this.ConvertDate(order.delivery_date)}
                    </Timeline.Item>
                  </Timeline>
                  <br />
                  <Divider orientation="left">Price information</Divider>
                  <p><b>Calculated price: </b> {order.unit_price} </p>
                  <p><b>Shipping Cost: </b> {order.shipping_cost} </p>
                  <p><b>Price: </b> {order.price}</p>
                  <p><b>VAT: </b> {order.vat}</p>
                  <p><b>Payment method: </b> {order.payment_method}</p>
                  <br />
                  <Divider orientation="left">Shipping information</Divider>
                  <p><b>Method: </b>{order.shipping_method}</p>
                  <p> <b>Shipping Cost: </b>{order.shipping_cost}</p>
                </Col>
                <Col flex={1}>
                  <Card title="Product information">
                    <h3>{order.amount}x</h3>
                    {Object.keys(description).map((keyName, i) => (
                      <li key={i}>
                        <span className="input-label">
                          <b>{keyName}</b> {description[keyName]}
                        </span>
                      </li>
                    ))}
                  </Card>
                  <br />
                  <Card title="Accept Order">
                    {/*<h3>*/}
                    {/*  Give order to{" "}*/}
                    {/*  <SupplierList*/}
                    {/*    supplier={this.state.supplier}*/}
                    {/*    supplierlist={this.state.supplierlist}*/}
                    {/*  />*/}
                    {/*</h3>*/}

                    <ChangeStatusButton
                      statusData={
                        this.AcceptedOrder(order,
                          calculation,
                          sender_address,
                          delivery_address,
                          this.ConvertDate(order.order_date),
                          this.ConvertDate(order.delivery_date))}

                    />
                  </Card>
                  <br />
                  <Card title="Reject the order">
                    <p>
                      <b>Order rejected because: </b>{order.order_status_message}
                    </p>
                    <TextArea
                      rows={4}
                      allowClear
                      showCount
                      placeholder="To reject the order, the reason must be longer than 10 characters."
                      onChange={(event) => {
                        let message = event.target.value

                        if (message.length > 10) {
                          this.setState({
                            message: message,
                            buttonRejected: false
                          });
                        } else {
                          this.setState({ buttonRejected: true })
                        }
                      }}
                    />
                    {this.state.buttonRejected ?
                      <Button disabled danger> Rejected order</Button>
                      :
                      <ChangeStatusButton statusData={this.RejectStatus()} />
                    }
                  </Card>
                  <br />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Print information" key="2">
              <Title level={2}>Print information</Title>
              <Row>
                <Col flex={1}>
                  <DownloadableFiles order={order} />
                  <br />
                  <Card title="Calculation outcome">
                    <ShowCalculations calc={calculation} />
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Delivery" key="3">
              <Title level={2}>Delivery information</Title>

              <Row>
                <Col flex={1}>
                  <Card title="Delivery address">
                    {delivery_address && Object.keys(delivery_address).map((keyName, i) => (
                      <li key={i}>
                        <span className="input-label">
                          <b>{keyName}</b> {delivery_address[keyName]}
                        </span>
                      </li>
                    ))}
                  </Card>
                </Col>
                <Col flex={1}>
                  <Card title="Sender address">
                    {Object.keys(sender_address).map((keyName, i) => (
                      <li className="travelcompany-input" key={i}>
                        <span className="input-label">
                          <b>{keyName}</b> {sender_address[keyName]}
                        </span>
                      </li>
                    ))}
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </>
      );
    } else {
      //IF NO DATA COULD BE LOADED
      return (
        <>
          <Empty />
          <p>{JSON.stringify(readerror.message)}</p>
        </>
      );
    }
  }
}

export const Checking = withRouter(CheckingComponent);

class SupplierList extends React.Component {

  updateChild(value) {
    console.log(value);
    updateState.apply(this,value);
  }

  render() {
    if (this.props.supplier === null) {
      return (
        <>
          <Select
            defaultValue="select supplier"
            style={{ width: 220 }}
            onChange={this.updateChild}
          >
            {this.props.supplierlist.map((element) => {
              return (
                <Option key={element.id} value={element.name}>
                  {element.name}
                </Option>
              );
            })}
          </Select>
        </>
      );
    } else {
      return <p>{this.props.supplier}</p>;
    }
  }
}
