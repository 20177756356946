import React from "react";
import { Steps } from 'antd';
import Cookies from "universal-cookie";

const cookies = new Cookies();

export class StepsMod extends React.Component {
    Permission(Step) {
        if (cookies.get('productsAllowed') !== undefined) {
            if (cookies.get('productsAllowed')[0] === "all") {
                return <Step title="Live orders" description="Brandnew order, assign supplier when files are ok!" />
            } else {
                return
            }
        }
    }
    render() {
        const Step = Steps.Step;
        var stepcounter = parseInt(this.props.stepcounter);
        if (cookies.get('productsAllowed') !== undefined) {
            if (cookies.get('productsAllowed')[0] !== "all") {
                stepcounter -= 1;
            }
        }


        return (
            <Steps current={stepcounter} style={{ marginBottom: '50px' }}>
                {this.Permission(Step)}
                <Step title="New" description="These are your new orders" />
                <Step title="Production" description="Files are getting printed" />
                <Step title="Shipping" description="Order is finished and ready to be shipped" />
                <Step title="Done" description="No explanation needed :)" />
            </Steps>
        )
    }
}
