import {
  Form,
  Input,
  Button,
  Avatar,
  Alert,
  Descriptions,
  Spin,
} from "antd";
import React from "react";
import Cookies from "universal-cookie";

import { Stack } from "../components";
import { Routes } from "../constants";
import {getSuppliers, isLoggedIn, loggedUserName, logIn, logOut, navigate} from "../services";

const cookies = new Cookies();

export class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      supplierInfo: {},
      productsAllowed: [],
      errorLogin: false,
      descriptionError: "",
      loading: false
    };
  }

  componentDidMount = async () => {
    if (!isLoggedIn()) return;
    this.setState({ loading: true });

    const suppliers = await getSuppliers();
    const supplier = suppliers.data.filter(x => x.name && x.name === loggedUserName())[0];

    if (supplier) this.setState({ supplierInfo: supplier, productsAllowed: supplier.productsAllowed, loading: false });
  };

  submitLogin = async (values) => {
    let creds = {
      "identifier": values.username,
      "password": values.password
    };

    this.setState({ loading: true });

    try {
      await logIn(creds);
      this.setState({ loading: false });
      navigate(Routes.Root, true);
    }
    catch (error) {
      this.setState({ loading: false, errorLogin: true,  descriptionError: error.message});
    }
  }


  render() {
    const { supplierInfo, productsAllowed, loading } = this.state;
    
    if (loading) return <Spin />;

    if (!isLoggedIn()) {
      return (
        <>
          <Form style={{ marginTop: 100, display: 'flex', justifyContent: 'center'}}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 6 }}
            initialValues={{ remember: true }}
            onFinish={(e) => this.submitLogin(e)}
            autoComplete="off"
          >
            <Stack>
              <Form.Item label="Email" name="username" rules={[{ required: true, message: "Please input your emai!", },]}>
                <Input />
              </Form.Item>
              <Form.Item label="Password" name="password" rules={[{ required: true, message: "Please input your password!", },]}>
                <Input.Password />
              </Form.Item>

              <Form.Item wrapperCol={{ name: "remember", offset: 10, span: 16, }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Stack>
          </Form>

          {this.state.errorLogin &&
            <Alert message={this.state.errorLogin} description={this.state.descriptionError}
              type="error" closable showIcon
              afterClose={() => this.setState({ errorLogin: false, })}
            />
          }
        </>
      );
    }
    else {
      return <>
        <h2>PROFILE</h2>
        <div className="inko-profile-header">
          <Avatar size={36}>
            {cookies.get('user').substr(-20, 2).toUpperCase()}
          </Avatar>
          <h3 className="title-profile ">{supplierInfo.name}</h3>
        </div>
        <Descriptions title="Your profile information" bordered>
          <Descriptions.Item label="Name" span={3}>
            {supplierInfo.name}
          </Descriptions.Item>
          <Descriptions.Item label="Email" span={3}>
            {supplierInfo.email}
          </Descriptions.Item>
          <Descriptions.Item label="Phone number" span={3}>
            {supplierInfo.phonenumber}
          </Descriptions.Item>
          <Descriptions.Item label="City" span={3}>
            {supplierInfo.city}
          </Descriptions.Item>
          <Descriptions.Item label="Site" span={3}>
            {supplierInfo.Site}
          </Descriptions.Item>
          <Descriptions.Item label="Postal code" span={3}>
            {supplierInfo.postalcode}
          </Descriptions.Item>

          <Descriptions.Item label="Products Allowed">
            {productsAllowed.map((product) => {
              return <p c>{product}</p>;
            })}
          </Descriptions.Item>
        </Descriptions>
        <br></br>

        <Button onClick={() => logOut()}>Log Out</Button>
      </>;
    }
  }
}