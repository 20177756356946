export const Routes = {
    Root: '/',
    Login: '/login',
    Planning: '/planning',
    PlanningES: '/planning-es',
    LiveOrders: '/live-orders',
    LiveOrdersES: '/live-orders-es',
    New: '/new',
    NewES: '/new-es',
    InProduction: '/in-production',
    InProductionES: '/in-production-es',
    Shipping: '/shipping',
    ShippingES: '/shipping-es',
    Done: '/done',
    DoneES: '/done-es',
    Users: '/users',
    UsersES: '/users-es',
    Documentation: '/documentation',

    Archive: '/archive/:country/:id',
    ToBeProduced: '/to-be-produced/:country/:id',
    ToBeDone: '/to-be-done/:country/:id',
    ToShip: '/to-ship/:country/:id',
    Checking: '/checking/:country/:id',
}

export function formatRoute(route, obj) {
    let formatted = route;
    Object.keys(obj).forEach(key => {
        formatted = formatted.replace(`:${key}`, obj[key]);
    });
    return formatted;
}
