import React from "react";

export class ShowCalculations extends React.Component {
    ShowImpositionInformation(element, index) {
        switch (index) {
            case 0: return element + " pages";
            case 1: return element + " plates";
            case 2: return element + " sheets";
            case 3: return element + " sheets inset";
            case 4: return element + "x";
            case 5: return element;
            default: return;
        }
    }
    GetKeyInformation(type, index) {

        if (type[1] === "digi") {
            var objEerste = [{
                key: '1',
                Price: type[0],
                Printer: type[1],
                CostClick: type[2],
                RectoVerso: type[3],
                CostPaper: Math.ceil(type[4] * 100) / 100,
                Sheetamount: type[5],
                Impressionspersheet: type[6],
                CostFinish: type[7],
                PriceDigitalPaper: type[8],
                Weight: type[9],
                Kind: (type[11] === 1) ? "Cover" : "Insert"
            }];
            return objEerste;
        }
        if (type[1] === "CX104") {
            var objTweede = [{
                key: '2',
                Price: type[0],
                Printer: type[1],
                Ink: type[2],
                CTP: type[5],
                Stelkost: type[6],
                Operating: type[7],
                Finishing: type[8],
                Weight: type[9],
                ImpositionScheme: type[10][0].map((element, index) => { ///// HIER AAN TE PASSEN VOOR MEERDERE LIJNEN IMPOSITIESCHEMA
                    return this.ShowImpositionInformation(element, index);
                }),
                PaperSize: type[11],
                Kind: (type[17] === 1) ? "Cover" : "Insert",

            }];
            return objTweede;
        }

        else return type;
    };
    render() {
        var eerste = [];
        var tweede = [];
        var eentje = [];
        if (typeof this.props.calc === "object") {
            eerste = this.props.calc.eerste;
            tweede = this.props.calc.tweede;
            eentje = this.props.calc;
        }

        if (eerste !== undefined) {
            return (
                <>

                    <pre>{JSON.stringify(this.GetKeyInformation(eerste), null, 2)}</pre>
                    <pre>{JSON.stringify(this.GetKeyInformation(tweede), null, 2)}</pre>
                    <hr />
                    {JSON.stringify(eentje)}<hr />
                </>
            )
        } if (eentje) {
            return (
                <>
                    <pre>{JSON.stringify(this.GetKeyInformation(eentje), null, 2)}</pre>
                </>
            )
        } else {
            return <pre>{JSON.stringify(eentje)}</pre>
        }
    }
};