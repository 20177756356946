import './App.css';

import DefaultLayout from './layouts/default';

import { useInitHistory, } from './services/app';

export default function App() {
  useInitHistory();

  return <DefaultLayout />;
}
