/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { Col, Collapse, Divider, Row, Timeline, Typography } from 'antd';
import Cookies from 'universal-cookie';
import { CaretRightOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';

const { Panel } = Collapse;
const { Title } = Typography;

const cookies = new Cookies();

export class Documentation extends React.Component {


  render() {
    if (!cookies.get('user')) return <p>Not logged in!</p>;

    const permissionADM = cookies.get('productsAllowed').some(productsAllowed => productsAllowed === 'all');
    const permissionNew = cookies.get('productsAllowed').some(productsAllowed => productsAllowed === 'New');
    const permissionProdution = cookies.get('productsAllowed').some(productsAllowed => productsAllowed === 'inProduction');
    const permissionShipping = cookies.get('productsAllowed').some(productsAllowed => productsAllowed === 'Shipping');
    const permissionDone = cookies.get('productsAllowed').some(productsAllowed => productsAllowed === 'Done');


    function callback(key) {
      return key;
    }
    return (
      <>
        <h2> Documentation </h2>
        <h3>Get started</h3>
        <p>Learn how to login, create a user, manage orders in our system and other questions.</p>
        <Collapse
          onChange={callback}
          bordered={false}
          className="site-collapse-custom-collapse"
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        >
          {/*HOW TO LOGIN? */}
          {permissionADM ?
            (
              <Panel header="How to login?" key="1" className="site-collapse-custom-panel bold">
                <p>To login you must click on the "Login" button.</p>
                <p>if you already have a username and password, just login. </p>
                <p>if you want to create a new login for a supplier you must access the database from db.inkosmart.be.</p>
                <Collapse>
                  <Panel header="How to create a supplier in db.inkosmart.be?" key="1" ghost>
                    <br></br>
                    <Timeline>
                      <Timeline.Item> You need to access the database page: <a href='https://db.inkosmart.be/'>db.inkosmart.be</a>.</Timeline.Item>
                      <Timeline.Item> Choose "Suppliers" Column.</Timeline.Item>
                      <Timeline.Item>Click on the button to create new supplier.</Timeline.Item>
                      <Timeline.Item>Fill in the fields to create the new supplier.</Timeline.Item>
                    </Timeline>

                    <p>The fields for the new supplier:</p>

                    <ul>
                      <li>Name</li>
                      <li>Street </li>
                      <li>PostalCode</li>
                      <li>City </li>
                      <li>HouseNo </li>
                      <li>Phonenumber</li>
                      <li>Country</li>
                      <li>Email</li>
                      <li>Products Allowed</li>
                    </ul>

                    <p>It is VERY important to correctly fill in the "Products Allowed"</p>
                    <p>Change supplier permission to view a specific status.</p>

                    <Row>
                      <Col span={6}>
                        <p>Pages access options:</p>
                        <ul>
                          <li>"all" (admins only)</li>
                          <li>"New"</li>
                          <li>"inProduction"</li>
                          <li>"Shipping" </li>
                          <li>"Done"</li>
                        </ul>
                      </Col>

                      <Col span={12}>
                        <p> And also put which country the supplier has access to: </p>
                        <ul>
                          <li>InkoprintBE</li>
                          <li>InkoprintES</li>
                        </ul>
                      </Col>
                    </Row>
                    <Divider />

                    <p>Example IF the supplier should only have access to inkoprintBE production.</p>
                    <p><b>The writing has to be like this, don't forget the square brackets, quotes and commas to separate</b></p>
                  </Panel>
                </Collapse>
              </Panel>
            )
            :
            ''
          }

          {/*HOW TO FIND AN ORDER  */}
          <Panel header="How to search for an order?" key="2" className="site-collapse-custom-panel">
            <Text italic> <br></br>You can search for your order by: reference, contact/customer, and product information. <br></br></Text>
            <br></br>
            <Timeline>
              <Timeline.Item>To search for an order, just click on the input "Search order..." in the side menu</Timeline.Item>
              <Timeline.Item> Enter your search.</Timeline.Item>
              <Timeline.Item>Press enter.</Timeline.Item>
              <Timeline.Item>Choose your order.</Timeline.Item>
            </Timeline>
            <p>If you enter your complete order reference, and it exists, it will redirect to the order page. </p>
          </Panel>


          {/*HOW TO USE THE CALENDAR  */}
          <Panel header="How to use 'planning' to view all orders with timeline" key="3" className="site-collapse-custom-panel">
            <Text italic> <br></br>With planning we can view all orders and check which one is closer to the delivery date. <br></br></Text>
            <br></br>
            <Timeline>
              <Timeline.Item>Click "Planning" in the side menu</Timeline.Item>
              <Timeline.Item>View by calendar which order you want. </Timeline.Item>
              <Timeline.Item>Click on order.</Timeline.Item>
              <Timeline.Item>View all order details</Timeline.Item>
            </Timeline>

          </Panel>

          {/*HOW TO ACCEPTED ORDER IN LIVE ORDERS */}

          <Panel header="1. STEP - How to accept an order in 'Live Orders'?" key="4" className="site-collapse-custom-panel">

            <p>By clicking on Live Orders, we can filter orders and check which orders are missing to be checked.</p>
            <p>We can filter orders from inkoprint.be by Status and by max days old <br></br></p>
            <br></br>
            <Timeline>
              <Timeline.Item>Filter orders</Timeline.Item>
              <Timeline.Item>Choose your order. (click in Open)</Timeline.Item>
              <Timeline.Item>View and check order</Timeline.Item>
              <Timeline.Item>Accept order and assign to a supplier</Timeline.Item>

            </Timeline>
          </Panel>

          {/*HOW TO VIEW NEW ORDERS */
            permissionNew || permissionADM ?
              <Panel header="2. STEP - How to view 'New' orders?" key="5" className="site-collapse-custom-panel">

                <p>By clicking "New" in the side menu, we will be able to view all new orders.</p>
                <p>At this stage, we will either accept the production order or refuse the order for any reason. <br></br></p>
                <br></br>

                <Title level={5}>Accepted order:</Title>
                <br></br>
                <Timeline>
                  <Timeline.Item>Click on "New" in the side menu.</Timeline.Item>
                  <Timeline.Item>Choose your order. (click in Open)</Timeline.Item>
                  <Timeline.Item>View and check order</Timeline.Item>
                  <Timeline.Item color='green'>Accept order and assign the order to production</Timeline.Item>
                </Timeline>

                <Title level={5}>Refuse order: </Title>
                <br></br>
                <Timeline>
                  <Timeline.Item>Click on "New" in the side menu.</Timeline.Item>
                  <Timeline.Item>Choose your order. (click in Open)</Timeline.Item>
                  <Timeline.Item>View and check order</Timeline.Item>
                  <Timeline.Item>Write the reason why you are refusing the request</Timeline.Item>
                  <Timeline.Item color='red'>Click in the button "Rejected this order"</Timeline.Item>

                </Timeline>
              </Panel>
              :
              ''
          }
          {/*HOW TO VIEW IN PRODUCTION ORDERS */
            permissionProdution || permissionADM ?
              <Panel header="3. STEP - How to view 'InProduction' orders?" key="6" className="site-collapse-custom-panel">
                <p>By clicking "in Production" in the side menu, we will be able to view all orders that have been accepted for production</p>
                <p>At this stage, we will be able to see which orders are in the production phase.</p>
                <p>and confirm the order when it is ready for delivery or extend the order delivery deadline for any reason.<br></br></p>
                <br></br>

                <Title level={5}>Confirming that the order is ready for delivery:</Title>
                <br></br>
                <Timeline>
                  <Timeline.Item>Click "In Production" in the side menu.</Timeline.Item>
                  <Timeline.Item>Choose your order. (click in Open)</Timeline.Item>
                  <Timeline.Item>View and check order</Timeline.Item>
                  <Timeline.Item color='green'>Click the "Ready to ship" button to confirm that the order is ready for delivery.</Timeline.Item>
                </Timeline>

                <Title level={5}>Change shipping date: </Title>
                <br></br>
                <Timeline>
                  <Timeline.Item>Click on "New" in the side menu.</Timeline.Item>
                  <Timeline.Item>Choose your order. (click in Open)</Timeline.Item>
                  <Timeline.Item>View and check order</Timeline.Item>
                  <Timeline.Item>Choose the new order delivery date</Timeline.Item>
                  <Timeline.Item>Write the reason why the order delivery date was extended. (must be longer than 10 characters)</Timeline.Item>
                  <Timeline.Item color='red'>Click in the button "Change shipping date"</Timeline.Item>
                </Timeline>
                <p></p>
              </Panel>
              :
              ''
          }

          {/*HOW TO VIEW SHIPPING ORDERS */
            permissionShipping || permissionADM ?

              <Panel header="4. STEP - How to view 'Ready to Ship' orders?" key="7" className="site-collapse-custom-panel">
                <p>By clicking "Shipping" in the side menu, we will be able to view all orders that are ready for delivery.</p>
                <p>At this stage we will be able to see which orders are ready for delivery and create a shipment.</p>
                <p>We will be able to see the preferred delivery method and choose the best options to make labels.<br></br></p>
                <br></br>

                <Title level={5}>Creating an order delivery label:</Title>
                <br></br>
                <Timeline>
                  <Timeline.Item>Click "Shipping" in the side menu.</Timeline.Item>
                  <Timeline.Item>Choose your order. (click in Open)</Timeline.Item>
                  <Timeline.Item>View and check order</Timeline.Item>
                  <Timeline.Item>Select quantity</Timeline.Item>
                  <Timeline.Item>Select type</Timeline.Item>
                  <Timeline.Item>Select Courier</Timeline.Item>
                  <Timeline.Item>Select date</Timeline.Item>
                  <Timeline.Item color='green'>Click the "Make Labels" button to confirm the creation of the label and send the order for delivery.</Timeline.Item>
                </Timeline>
              </Panel>
              :
              ''
          }

          {/*HOW TO VIEW DONE ORDERS */
            permissionDone || permissionADM ?

              <Panel header="5. STEP - How to view 'Done' orders?" key="8" className="site-collapse-custom-panel">
                <p>By clicking "Done" in the side menu, we will be able to view all orders that have been delivered.</p>
                <p>At this stage, we will be able to see the details of the orders that have been delivered and download the delivery label.</p>

                <br></br>

                <Title level={5}>Download shipping labels:</Title>
                <br></br>
                <Timeline>
                  <Timeline.Item>Click "Done" in the side menu.</Timeline.Item>
                  <Timeline.Item>Choose your order. (click in Open)</Timeline.Item>
                  <Timeline.Item>View and check order</Timeline.Item>
                  <Timeline.Item color='green'>Click the "Download labels" button.</Timeline.Item>
                </Timeline>
              </Panel>
              :
              ''
          }


        </Collapse>


      </>
    )
  }
}
