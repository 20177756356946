import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Layout } from 'antd';

import {
  Archive,
  New,
  Done,
  InProduction,
  Checking,
  LiveOrders,
  Landing,
  Shipping,
  ToBeDone,
  ToBeProduced,
  ToShip,
  Users
} from "../inkoprint";

import {
  TopMenu,
  Login,
  Planning,
  Documentation,
  SearchModal,
  Sider,
} from "../modules";

import { isAdm, isLoggedIn, userHasAccess } from '../services/app';
import { Routes } from "../constants";

const { Header, Content } = Layout;

export default function DefaultLayout() {

  return <Layout>
    <Header className="header">
      <TopMenu />
    </Header>
    <Layout>
      <div id="rowcontent">
        {isLoggedIn() && <Sider />}
        <Layout style={{
          padding: '24px 24px 24px',
          minHeight: '80vh'
        }}>

          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route path="/login" component={Login} />

              {isLoggedIn() && userHasAccess() ?
                <>
                  {isAdm('BE') && <Route path={Routes.LiveOrders} component={LiveOrders} />}
                  {isAdm('ES') && <Route path={Routes.LiveOrdersES} component={LiveOrders} />}
                  <Route path={Routes.Checking} component={Checking} />
                  <Route path={Routes.New} component={New} />
                  <Route path={Routes.NewES} component={New} />
                  <Route path={Routes.ToBeProduced} component={ToBeProduced} />
                  <Route path={Routes.InProduction} component={InProduction} />
                  <Route path={Routes.InProductionES} component={InProduction} />
                  <Route path={Routes.ToShip} component={ToShip} />
                  <Route path={Routes.Shipping} component={Shipping} />
                  <Route path={Routes.ShippingES} component={Shipping} />
                  <Route path={Routes.ToBeDone} component={ToBeDone} />
                  <Route path={Routes.Done} component={Done} />
                  <Route path={Routes.DoneES} component={Done} />
                  <Route path={Routes.Archive} component={Archive} />
                  <Route path={Routes.Planning} component={Planning} />
                  <Route path={Routes.PlanningES} component={Planning} />
                  <Route path={Routes.Users} component={Users} />
                  <Route path={Routes.UsersES} component={Users} />
                  <Route path="/searchmodal/" render={() => <SearchModal />} />
                  <Route path={Routes.Documentation} component={Documentation} />
                </>
                :
                <Redirect to='/' />
              }
            </Switch>
          </Content>
        </Layout>
      </div>
    </Layout>
  </Layout>;
}

