import axios from 'axios';
import Cookies from 'universal-cookie';

import {dbUrl, inkoprintEsUrl, inkoprintUrl, transSmartUrl} from '../constants';
import {isBelgium, isLoggedIn} from './app';

const cookies = new Cookies();

//=======================================
//==============INKOPRINT================
//=======================================

const getBaseInkoprintConfig = (country) => {
        const isES = country === 'ES'
        const token = isES ? cookies.get('TOKENinkoES') : cookies.get('TOKENinko')
        return {
            headers: {
                ...isLoggedIn() ? {'Authorization': token} : undefined,
                "Content-Type": "text/plain",
            },
        }
    }

;

export async function inkoprintRequest(method, url, body, country) {
    const newConfig = {
        method: method,
        url: (isBelgium(country) ? inkoprintUrl : inkoprintEsUrl) + url,
        ...body ? {data: body} : undefined,
        ...getBaseDbConfig()
    };
    console.log(`Sending ${method}:`);
    console.log(newConfig);
    return axios(newConfig);
}

export async function inkoprintGet(getUrl, country, params) {
    const newConfig = {
        method: 'get',
        url: (isBelgium(country) ? inkoprintUrl : inkoprintEsUrl) + getUrl,
        ...getBaseInkoprintConfig(country),
        ...params
    };
    console.log('Sending GET:');
    console.log(newConfig);
    return axios(newConfig);
}

export async function inkoprintPost(postUrl, body, country) {
    const newConfig = {
        method: 'post',
        url: (isBelgium(country) ? inkoprintUrl : inkoprintEsUrl) + postUrl,
        data: body,
        ...getBaseInkoprintConfig(country)
    };
    console.log('Sending POST:');
    console.log(newConfig);
    return axios(newConfig);
}

export async function inkoprintPut(putUrl, body, country) {
    const newConfig = {
        method: 'put',
        url: (isBelgium(country) ? inkoprintUrl : inkoprintEsUrl) + putUrl,
        data: body,
        ...getBaseInkoprintConfig(country)
    };
    console.log('Sending POST:');
    console.log(newConfig);
    return axios(newConfig);
}

//=======================================
//=================DB====================
//=======================================

const getBaseDbConfig = () => ({
    headers: {
        ...isLoggedIn() ? {'Authorization': cookies.get('JWT')} : undefined,
        'Content-Type': 'application/json',
    }
});

export async function dbRequest(method, url, body) {
    const newConfig = {
        method: method,
        url: dbUrl + url,
        ...body ? {data: body} : undefined,
        ...getBaseDbConfig()
    };
    console.log(`Sending ${method}:`);
    console.log(newConfig);
    return axios(newConfig);
}

export async function dbGet(getUrl) {
    const newConfig = {
        method: 'get',
        url: dbUrl + getUrl,
        ...getBaseDbConfig()
    };
    console.log('Sending GET:');
    console.log(newConfig);
    return axios(newConfig);
}

export async function dbPost(postUrl, body) {
    const newConfig = {
        method: 'post',
        url: dbUrl + postUrl,
        data: body,
        ...getBaseDbConfig()
    };
    console.log('Sending POST:');
    console.log(newConfig);
    return axios(newConfig);
}

export async function dbPut(putUrl, body) {
    const newConfig = {
        method: 'put',
        url: dbUrl + putUrl,
        data: body,
        ...getBaseDbConfig()
    };
    console.log('Sending POST:');
    console.log(newConfig);
    return axios(newConfig);
}


//=======================================
//=============TransSmart================
//=======================================

export async function loginToTransSmart() {
    const myHeaders = new Headers();
    myHeaders.append(
        "Authorization",
        "Basic YXBpQGJ1cm9mb3JtLmJlOm5MZjNhJU5Yc2FQeSU9TUM="
    );

    var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    const response = await fetch(transSmartUrl + "login", requestOptions);

    if (!response.ok) throw Error('Failed to sign in to TransSmart');

    return response.json();
}

export async function transSmartGet(url) {
    const result = await loginToTransSmart();

    var token = "Bearer " + result.token;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    const response = await fetch(transSmartUrl + url, requestOptions);

    if (!response.ok) throw Error('Failed to post to TransSmart');

    return response.json();
}

export async function transSmartPost(body) {
    const result = await loginToTransSmart();

    var token = "Bearer " + result.token;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: body,
        redirect: "follow",
    };

    const response = await fetch(transSmartUrl + "v2/shipments/BUROFORM/BOOK", requestOptions);

    if (!response.ok) throw Error('Failed to post to TransSmart');

    return response.json();
}