import React from 'react'
import {Link} from 'react-router-dom';
import {SearchOutlined, CloseOutlined, FrownOutlined, EditOutlined} from '@ant-design/icons';
import {Alert, Button, Spin} from "antd";
import {getCountryCode, getInkoprintOrders, getOrders, isAdm, logOut} from '../services';
import {formatRoute, Routes} from '../constants';

export class SearchModal extends React.Component {
    constructor(props) {
        super(props);
        const wordInput = this.props.form

        this.state = {
            allOrders: [],
            filteredOrders: [],
            inkoPrintOrders: [],
            filteredInkoPrint: [],
            loading: true,
            supplierOrder: '',
            word: wordInput,
            searchTerm: this.props.term,
            errorMessage: '',
            hasError: false
        }
    }

    componentDidMount = async () => {
        const word = this.state.word;
        let response = await getOrders();
        this.setState({
            allOrders: response.data,
            filteredOrders: response.data,
        });
        this.search(word);

        if (isAdm()) {
            try {
                response = await getInkoprintOrders();
                this.setState({inkoPrintOrders: response.data});
                this.search(word);
            } catch (e) {
                this.setState({
                    hasError: true,
                    errorMessage: e?.message || 'Something went wrong',
                })
            }
        }
        this.setState({
            loading: false,
        });
    }

    search = () => {
        const term = this.state.searchTerm;
        const {allOrders, inkoPrintOrders} = this.state;

        //FUNCTION TO FILTER ORDERS WITH TYPED WORDS (ORDERS STRAPI)
        const filtered = allOrders.filter((order) => {

            const customerName = order.receiver && order.receiver.contact ? order.receiver.contact : '';
            const descriptionLong = order.description_long && order.description_long.Bedrukking ? order.description_long.Bedrukking : '';

            return (order.reference && order.reference.includes(term))
                || (order.description && order.description.toLowerCase().includes(term.toLowerCase()))
                || (customerName && customerName.toLowerCase().includes(term.toLowerCase()))
                || (descriptionLong && descriptionLong.toLowerCase().includes(term.toLowerCase()))

        });
        this.setState({filteredOrders: filtered});
        //FUNCTION TO FILTER ORDERS WITH TYPED WORDS (ORDERS API INKOPRINT)
        const filteredIPrint = inkoPrintOrders.filter((element) => {

            const contactName = element.delivery_address && element.delivery_address.contact ? element.delivery_address.contact : '';

            return (element.order_id && (element.order_id + '').includes(term))
                || (contactName && contactName.toLowerCase().includes(term.toLowerCase()))
        });

        this.setState({filteredInkoPrint: filteredIPrint})
    }


    handleChange = (event) => {
        this.setState({
            word: event.target.value,
            searchTerm: event.target.value
        }, () => this.search())
    }

    clearInput = () => {
        this.setState({
            searchTerm: '',
            word: '',
        }, () => this.search())
    }

    render() {

        const showAlert = this.state.hasError ? <Alert action={
            <Button size="small" type="primary" onClick={() => {
                this.props.hideModal()
                logOut()
            }}>
                re-login
            </Button>
        } className="error-alarm" message={this.state.errorMessage} closable type="error" banner={true}/> : null

        const {filteredInkoPrint, filteredOrders} = this.state;

        return (
            <>
                {showAlert}
                <div className="search">
                    <form>
                        {this.state.word.length > 0 ?
                            <h5 className='numberSearch'> {this.state.filteredOrders.length} Orders found</h5>
                            :
                            <h5 className='numberSearch'> Type for the search </h5>
                        }
                        <h5 className='wordSearch'>Your search: <strong>"{this.state.word}" </strong></h5>

                        <div className="searchInputs">

                            <input type="text"
                                   placeholder="Search order..."
                                   value={this.state.word}
                                   onChange={this.handleChange}
                                   autoComplete='off'/>
                            <div className="searchIcon">
                                {this.state.word === '' ?
                                    <SearchOutlined/>
                                    :
                                    <CloseOutlined onClick={this.clearInput}/>
                                }
                            </div>

                        </div>

                    </form>
                    <div className=' titleBar'>
                        <p className='referenceItem' style={{color: 'white'}}>reference</p>
                        <p className='descriptionItem'> Product Info </p>
                        <p className='contactItem' style={{color: 'white'}}>Contact</p>
                        <p className='statusItem'>Status</p>

                    </div>

                    {/* LOADING AWAIT THE RESPONSE.DATA SEARCH */}

                    <div className='dataResult'>
                        {this.state.loading ?
                            <div className='spin'>
                                <Spin size="large"/>
                            </div>
                            :
                            <div>

                                {/* .SLICE FOR TO LIMIT 10 MODAL ORDERS */}
                                {/* .MAP TO LIST THE ORDERS SEARCHED ON THE SCREEN*/}
                                {this.state.word.length > 0 ?
                                    <>
                                        {filteredOrders.slice(0, 10).map((element, i) => {
                                            const term = this.state.searchTerm;

                                            //LOGIC FOR TYPED WORD BOLD
                                            let reference = element.reference;
                                            let referenceEl = <>{element.reference}</>;
                                            if (reference) {
                                                if (reference.startsWith(term)) {
                                                    reference = reference.replace(term, '');
                                                    referenceEl = <><b>{term}</b>{reference}</>;
                                                } else if (reference.endsWith(term)) {
                                                    reference = reference.replace(term, '');
                                                    referenceEl = <>{reference}<b>{term}</b></>;
                                                } else if (reference.includes(term)) {
                                                    reference = reference.split(term);
                                                    referenceEl = <>
                                                        {
                                                            reference.map((frag, i) => {
                                                                return reference.length - 1 === i ? <>{frag}</> : <>{frag}<b>{term}</b></>;
                                                            })
                                                        }
                                                    </>;
                                                }
                                            }

                                            let description_long = element.description_long && element.description_long.Bedrukking ? element.description_long.Bedrukking : ''
                                            let descriptionlongEl = <>{element.description_long && element.description_long.Bedrukking ? element.description_long.Bedrukking : ''}</>;
                                            if (description_long) {
                                                if (description_long.toUpperCase().startsWith(term.toUpperCase())) {
                                                    description_long = description_long.toUpperCase().replace(term.toUpperCase(), '');
                                                    descriptionlongEl = <>
                                                        <b>{term.toUpperCase()}</b>{description_long.toUpperCase()}</>;
                                                } else if (description_long.toUpperCase().endsWith(term.toUpperCase())) {
                                                    description_long = description_long.replace(term, '');
                                                    descriptionlongEl = <>{description_long}<b>{term}</b></>;
                                                } else if (description_long.toUpperCase().includes(term.toUpperCase())) {
                                                    description_long = description_long.split(term);
                                                    descriptionlongEl = <>
                                                        {
                                                            description_long.map((frag, i) => {
                                                                return description_long.length - 1 === i ? <>{frag.toUpperCase()}</> : <>{frag.toUpperCase()}<b>{term.toUpperCase()}</b></>;
                                                            })
                                                        }
                                                    </>;
                                                }
                                            }

                                            let contact = element.receiver && element.receiver.contact ? element.receiver.contact : '';
                                            let contactEl = <>{element.receiver && element.receiver.contact ? element.receiver.contact : ''}</>;
                                            if (contact) {
                                                if (contact.toUpperCase().startsWith(term.toUpperCase())) {
                                                    contact = contact.toUpperCase().replace(term.toUpperCase(), '');
                                                    contactEl =
                                                        <div key={i}><b>{term.toUpperCase()}</b>{contact.toUpperCase()}
                                                        </div>;
                                                } else if (contact.toUpperCase().endsWith(term.toUpperCase())) {
                                                    contact = contact.replace(term, '');
                                                    contactEl = <div key={i}>{contact}<b>{term}</b></div>;
                                                } else if (contact.toUpperCase().includes(term.toUpperCase())) {
                                                    contact = contact.split(term);
                                                    contactEl = <>
                                                        {
                                                            contact.map((frag, i) => {
                                                                return contact.length - 1 === i ? <>{frag.toUpperCase()}</> :
                                                                    <div
                                                                        key={i}>{frag.toUpperCase()}<b>{term.toUpperCase()}</b>
                                                                    </div>;
                                                            })
                                                        }
                                                    </>;
                                                }
                                            }

                                            //SAVING THE STATUS + REFERENCE OF THE ORDER TO CREATE THE ROUTE
                                            const router = routerStatus(element.Status, element.reference);

                                            return (
                                                <div key={i}>
                                                    <Link className='itemResult' onClick={() => this.props.hideModal()}
                                                          to={router}>

                                                        <p className='referenceItem'>{referenceEl && element.reference ? referenceEl : '-'}</p>
                                                        <p className='description_longItem'> {element.description_long && element.description_long.Bedrukking ? descriptionlongEl : '-'}</p>
                                                        <p className='contactItem'> {element.receiver && element.receiver.contact ? contactEl : '-'}</p>
                                                        <p className='statusItem'> {element.Status}</p>
                                                    </Link>
                                                </div>
                                            )
                                        })
                                        }
                                    </>
                                    :
                                    <div className='notFoundOrder'>
                                        <EditOutlined style={{fontSize: 20}}/>
                                        <p>Type more than 1 characters to search! </p>
                                    </div>
                                }


                                {filteredOrders.length === 0 && this.state.word.length > 1 ?
                                    filteredInkoPrint.map((element) => {
                                        let routerLiveOrders = formatRoute(Routes.Checking, {
                                            country: getCountryCode(),
                                            id: element.order_id
                                        });

                                        return (
                                            <div key={element.id}>
                                                <Link onClick={() => this.props.hideModal()}
                                                      className='itemResult itemInkoPrint'
                                                      to={routerLiveOrders}>
                                                    <p className='descriptionItem'>Order in
                                                        INKOPRINT: <strong>{element.order_id}</strong></p>
                                                    <p className='descriptionItem'>{element.description} </p>
                                                    <p className='contactItem'> {element.delivery_address.contact ? element.delivery_address.contact : "-"} </p>
                                                    <p className='contactItem'> {element.order_status} </p>
                                                </Link>
                                            </div>
                                        )
                                    })
                                    :
                                    ''}

                                {filteredOrders.length === 0 && filteredInkoPrint.length === 0 && this.state.word.length > 0 ?
                                    <div className='notFoundOrder'>
                                        <FrownOutlined style={{fontSize: 20}}/>
                                        <p>We didn't find this order! </p>
                                    </div>
                                    :
                                    ''}

                            </div>
                        }
                    </div>
                </div>
            </>
        )
    }
}

//SWITCH FOR STATUS ORDER
const routerStatus = (status, id) => {
    const
        routerObj = {country: getCountryCode(), id: id};

    switch (status) {
        case
        "New"
        :
            return formatRoute(Routes.ToBeProduced, routerObj);
        case
        "CheckingFiles"
        :
            return formatRoute(Routes.ToBeProduced, routerObj);
        case
        "Accepted"
        :
            return formatRoute(Routes.ToBeProduced, routerObj);
        case
        "InProduction"
        :
            return formatRoute(Routes.ToShip, routerObj);
        case
        "Shipping"
        :
            return formatRoute(Routes.ToBeDone, routerObj);
        case
        "Done"
        :
            return formatRoute(Routes.Archive, routerObj);
        case
        "Rejected"
        :
            return formatRoute(Routes.ToBeProduced, routerObj);
        default:
            return Routes.Root;
    }
};
