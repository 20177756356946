/* eslint-disable array-callback-return */
import React from "react";
import { Calendar, Badge, Spin, Modal, Timeline, Divider, Tabs, Row, Col, } from "antd";

import { getOrders } from "../services";

const { TabPane } = Tabs;

export class Planning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: true,
      inkoorders: [],
      order: {},
      description: {},
      sender: {},
      receiver: {},
    };
  }

  showModal = (order) => {
    this.setState({
      visible: true,
      order,
      description: order.description_long,
      sender: order.sender,
      receiver: order.receiver,
    });
  };

  handleOk = () => {
    this.setState({ visible: false });

  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  componentDidMount = async () => {
    const locationArr = this.props.location.pathname.split('-')
    const country = locationArr[locationArr.length - 1] === 'es' ? "ES": "BE"
    const response = await getOrders(country);
    this.setState({ inkoorders: response.data, loading: false, });
  };


  dateCellRender = (date) => {
    if (!this.state.inkoorders || this.state.inkoorders.length <= 0)
      return <></>;

    const day = date.date();
    const month = date.month() + 1;
    const year = date.year();

    return (
      <ul className="events">
        {this.state.inkoorders
          .filter((order) => {
            if (order.deliverydate) {
              const fragments = order.deliverydate.split("-");

              const itemDay = fragments[0] > 9 ? fragments[0] : fragments[0].slice(1);

              const itemMonth = fragments[1] > 9 ? fragments[1] : fragments[1].slice(1);

              const itemYear = fragments[2];

              return (
                // eslint-disable-next-line
                day + "-" + month + "-" + year == itemDay + "-" + itemMonth + "-" + itemYear

              );
            }
          })

          .map((order, i) => {
            //function for text in the badge
            const textBadge = () => {
              return `${order.amount}x  ${order.description}`;
            };

            return (
              <div key={i}>
                <li onClick={() => {
                  this.showModal(order);
                }}>
                  <Badge
                    className=""
                    status={renderStatus(order.Status)}
                    text={textBadge()} />
                </li>
              </div>
            );
          })
        }
      </ul>
    );
  };

  render() {
    const order = this.state.order;
    return (
      <>
        {
          this.state.loading ?
            <Spin />
            :
            <Calendar dateCellRender={this.dateCellRender} />
        }

        <Modal
          title={'Order: ' + this.state.order.reference + " • Description: " + order.description}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          centered
          footer={null}
        >

          <Tabs defaultActiveKey="1">
            {/* TAB 1 - DATE / STATUS */}
            <TabPane tab="Status" key="1">
              <Divider orientation="left">Status Information</Divider>
              <h4>{this.state.order.Status}</h4>

              <Divider orientation="left">Date information</Divider>

              <Timeline.Item>
                Order date was {this.state.order.orderdate}
              </Timeline.Item>
              <Timeline.Item>
                Shipping date {this.state.order.deliverydate}
              </Timeline.Item>
            </TabPane>

            {/* TAB 2 - PRODUCT INFORMATION*/}
            <TabPane tab="Product Information" key="2">
              <Divider orientation="left">Product information</Divider>
              <h3>{order.amount}x</h3>
              {Object.keys(this.state.description && this.state.description ? this.state.description : '').map((keyName, i) => (
                <li key={i}>
                  <span className="input-label">
                    <b>{keyName}: </b> {this.state.description[keyName]}
                  </span>
                </li>
              ))}
            </TabPane>

            {/* TAB 3 - DELIVERY*/}
            <TabPane tab="Delivery" key="3">
              <Divider orientation="left">Delivery Information</Divider>
              <Row>
                <Col flex={1}>
                  {Object.keys(this.state.receiver ? this.state.receiver : '').map((keyName, i) => (
                    (this.state.receiver[keyName] !== null) ?
                      <li key={i}>
                        <span className="input-label">
                          <b>{keyName}</b>
                          {this.state.receiver[keyName]}
                        </span>
                      </li>
                      :
                      ''
                  ))}
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Modal>
      </>

    )
  }
}

//FUNCTION FOR THE COLOR OF STATUS
const renderStatus = (value) => {
  //green = "success"
  //blue = "processing"
  //grey = "default"
  //orange = "warning"
  //red = "error"

  switch (value) {
    case "New":
      return "error";

    case "Accepted":
      return "warning";

    case "CheckingFiles":
      return "warning";

    case "InProduction":
      return "processing";

    case "Shipping":
      return "default";

    case "Done":
      return "success";

    case "Rejected":
      return "error";

    default:
      return console.log("Error in Status");
  }
};
