import React from 'react';
import {Table, Space, Spin, Select, InputNumber, Button, Alert} from 'antd';
import {Link} from 'react-router-dom';
import {StopOutlined} from '@ant-design/icons';

import {StepsMod} from '../modules';
import {getCountryCode, getInkoprintOrders, getOrders, logOut} from '../services';
import {formatRoute, Routes} from '../constants';

const {Option} = Select;

export class LiveOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorMessage: '',
            inkoorders: [],
            ordersProduction: [],
            selectsort: "",
            pagePermission: true,
            loading: false,
            filtertable: "Checking files",
            maxDays: -2
        }
    }

    componentDidMount = async () => {
        // const orders = await getOrders();
        try {
            const paramsStr = this.props.location.search
            let filter = this.state.filtertable
            if (paramsStr) {
                const paramsArr = paramsStr.split('=')
                if (paramsArr.length) {
                    filter = paramsArr[1]?.replace('%20', ' ')
                    this.filterTable(filter)
                }
            }
            else {
                this.props.history.push({search: `filter=${filter}`})
            }

            const locationArr = this.props.location.pathname.split('-')
            const country = locationArr[locationArr.length - 1] === 'es' ? "ES" : "BE"
            const orders = await getInkoprintOrders(country);
            this.setState({
                inkoorders: orders?.data,
                selectsort: orders?.delivery_date,
                loading: true,
            });

        } catch (e) {
            logOut()
            this.setState({
                inkoorders: [],
                hasError: true,
                errorMessage: e?.message || 'Something went wrong',
                loading: true
            })
        }
    }


    ConvertDate(value) {
        let date = value.toString();
        let year = date.slice(0, 4);
        let month = date.slice(4, 6);
        let day = date.slice(6, 8);
        return day + "-" + month + "-" + year;
    }

    RemainingDays(day) {
        const today = new Date();
        const parts = this.ConvertDate(day).split('-');
        const lastDay = new Date(parts[2], parts[1] - 1, parts[0]);
        return Math.ceil((lastDay - today) / (1000 * 3600 * 24));
    }

    filterTable(value) {
        console.log(this.state.filtertable);
        console.log(value);
        this.setState({filtertable: value});
        this.props.history.push({search: `filter=${value}`})
    }

    fMaxDays(value) {
        this.setState({maxDays: value - 1});
    }


    render() {
        const locationArr = this.props.location.pathname.split('-')
        const country = locationArr[locationArr.length - 1] === 'es' ? "ES" : "BE"
        const {inkoorders, filtertable} = this.state;

        function filterOrders(element) {
            if (filtertable.toLowerCase() === 'all') return element
            return element.order_status === JSON.stringify(filtertable).replace(/"/g, '')
        }

        function colorStatus(element) {
            if (element === "Rejected") {
                return <b style={{color: 'red'}}>{element}</b>
            } else {
                return element
            }
        }

        const dataSource = [];

        if (inkoorders && inkoorders?.length) {
            inkoorders
                .sort((a, b) => b?.delivery_date - a?.delivery_date)
                .filter(filterOrders)
                // .filter(element => this.RemainingDays(element.delivery_date) > this.state.maxDays)
                .forEach(element => {
                    return dataSource.push({
                        key: element.order_id,
                        reference: element?.order_id ? element?.order_id : '-',
                        main_order_id: element?.main_order_id ? element?.main_order_id : '-',
                        description: element.description,
                        delivery_date: element.delivery_date ? this.ConvertDate(element.delivery_date) : '',
                        remainingDays: element.delivery_date ? this.RemainingDays(element.delivery_date) : '',
                        sender_address_name: element.sender_address ? element.sender_address?.name : '',
                        delivery_address_name: element.sender_address ? element.delivery_address?.name : '',
                        status: element.order_status ? colorStatus(element.order_status) : '',
                        statusint: element.order_status_internal
                    });
                });
        }
        const columns = [
            {title: "Ref.", dataIndex: "reference", key: "reference"},
            {title: "Main ID", dataIndex: "main_order_id", key: "main_order_id"},
            {title: "Description", dataIndex: "description", key: "description"},
            {title: "Shipping date", dataIndex: "delivery_date", key: "delivery_date"},
            {title: "From name", dataIndex: "sender_address_name", key: "sender_address_name"},
            {title: "To name", dataIndex: "delivery_address_name", key: "delivery_address_name"},
            {title: "Remaining Days", dataIndex: "remainingDays", key: "remainingDays"},
            {title: "Status", dataIndex: "status", key: "status"},
            {title: "Status Int", dataIndex: "statusint", key: "statusint"},
            {
                title: "Action", key: "action", render: (text, record) => (
                    <Space size="middle">
                        <Link
                            to={formatRoute(Routes.Checking, {country: getCountryCode(country), id: record.reference})}>
                            <Button type="primary" value="small">
                                Open
                            </Button>
                        </Link>
                    </Space>
                ),
            }
        ]

        const showAlert = this.state.hasError ? <Alert action={
            <Button size="small" type="primary" onClick={() => {
                logOut()
            }}>
                re-login
            </Button>
        } className="error-alarm" message={this.state.errorMessage} closable type="error" banner={true}/> : null


        return <>
            {showAlert}
            <StepsMod stepcounter="0"/>
            {this.state.pagePermission ?
                (this.state.loading ?
                        <>
                            <b>Filter: </b>
                            <Select value={this.state.filtertable} style={{width: 240}}
                                    onChange={(e) => this.filterTable(e)}>
                                <Option value="All">All</Option>
                                <Option value="Checking files">Check files</Option>
                                <Option value="Accepted">Accepted</Option>
                                <Option value="Rejected">Rejected</Option>
                                <Option value="In production">In production</Option>
                                <Option value="Ready for shipment">Ready to ship</Option>
                                <Option value="Ready">Done</Option>
                                <Option value="Please upload new files">Waiting for files</Option>
                            </Select>

                            <InputNumber min={-1000} max={100} defaultValue={-10} onChange={(e) => this.fMaxDays(e)}/>max
                            days old
                            <Table dataSource={dataSource} columns={columns}/>
                        </>
                        :
                        <Spin/>
                ) :
                <div className='notFoundOrder'>
                    <StopOutlined style={{fontSize: 20}}/>
                    <p>You do not have permission for this page! </p>
                </div>
            }
        </>;
    }
}
