import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";

import { AppContextProvider } from './app.context';
import App from './App';

import './index.css';
import 'antd/dist/antd.css';

ReactDOM.render(
    <AppContextProvider>
        <Router>
            <App />
        </Router>
    </AppContextProvider>,
    document.getElementById('root')
);
