/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { getUsers, productsAllowed } from '../services';

export class Users extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userData: {},
    }
  }

  componentDidMount = async () => {
    const response = await getUsers();
    this.setState({ userData: response.data, });
  }

  render() {
    const permissionADM = productsAllowed();
    // const permissionNew = productsAllowed().some(productsAllowed => productsAllowed === 'New');
    // const permissionProdution = productsAllowed().some(productsAllowed => productsAllowed === 'inProduction');
    // const permissionShipping = productsAllowed().some(productsAllowed => productsAllowed === 'Shipping');
    // const permissionDone = productsAllowed().some(productsAllowed => productsAllowed === 'Done');

    return <>
      <h2>Users</h2>
      {permissionADM ?
        <h3>All users:</h3>
        :
        <h3>No access to update users!</h3>
      }
    </>;
  }
}
