import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Timeline, Divider, Card, Typography, Empty, Tabs } from 'antd';

import { DownloadableFiles, GetLabels, ShowCalculations } from '../modules';

import {getOrderById} from "../services";

const { TabPane } = Tabs;
const { Title } = Typography;

class ArchiveComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            strapiId: null,
            order: [],
            delivery_address: {},
            sender_address: {},
            calculation: [],
            description: {},
            orderID: null,
            readerror: [],
            country: this.props.match.params.country === 'ES'? 'ES': 'BE'
        }
    }


    componentDidMount = async () => {
        const id = this.props.match.params.id;
        const country = this.state.country
        console.log(id);
        this.setState({ orderID: id });


        try {
            const response = await getOrderById(id, country);

            this.setState({
                mainOrderID: response.data[0]?.main_order_id,
                strapiId: response.data[0].id,
                order: response.data[0],
                delivery_address: response.data[0].receiver,
                sender_address: response.data[0].sender,
                calculation: response.data[0].fullorder,
                description: response.data[0].description_long
            });

            // SET CALCULATIONS IF MORE THAN ONE
            if (typeof response.data[0].calculation['aantal vel papier'].eerste === "object") {
                this.setState({ calculation: response.data[0].calculation['aantal vel papier'] });
            }
            else {
                this.setState({ calculation: response.data[0].calculation });
            }
        }
        catch (error) {
            this.setState({ readerror: error });
        }
    };




    render() {
        const { order, orderID, readerror, sender_address, delivery_address, calculation, description, mainOrderID } = this.state;

        if (order.description !== undefined) {

            return (
                <>
                    <Row>
                        <Col flex={10}><Title>{orderID} ({mainOrderID}) - {order.description} - Done</Title></Col>
                    </Row>
                    <Tabs defaultActiveKey="1" onChange={this.callBack}>
                        <TabPane tab="Summary" key="1">
                            <Title level={2}>Summary</Title>
                            <Row>
                                <Col flex={1}>
                                    <Divider orientation="left">Date information</Divider>
                                    <br />
                                    <Timeline>
                                        <Timeline.Item>Order date was {order.orderdate}</Timeline.Item>
                                        <Timeline.Item>Shipping date {order.deliverydate}</Timeline.Item>

                                    </Timeline>
                                    <br />
                                    <Divider orientation="left">Price information</Divider>
                                    <p><b>Price: </b>{order.price}</p>
                                    <br />
                                    <Divider orientation="left">Shipping information</Divider>
                                    <p><b>Order is shipped!</b></p>
                                    <p><b>Tracking code: </b><a href={order.TranssmartOptions?.tracktrace} target="_blank" rel="noopener noreferrer">Click here</a></p>
                                </Col>
                                <Col flex={1}>
                                    <Card title="Product information">
                                        <h3>{order.amount}x</h3>
                                        {Object.keys(description).map((keyName, i) => (
                                            <li key={i}>
                                                <span className="input-label"><b>{keyName}</b> {description[keyName]}</span>
                                            </li>
                                        ))}
                                    </Card>
                                    <br /><br />
                                    <Card title="Download shipping labels">
                                        <GetLabels reference={this.state.order.reference} country={this.state.country}/>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="Print information" key="2">
                            <Title level={2}>Print information</Title>
                            <Row>
                                <Col flex={1}>
                                    <DownloadableFiles order={order} />
                                    <br />
                                    <Card title="Calculation outcome">
                                        <ShowCalculations calc={calculation} />
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="Delivery" key="3">
                            <Title level={2}>Delivery information</Title>
                            <Row>
                                <Col flex={1}>
                                    <Card title="Delivery address">
                                        {Object.keys(delivery_address).map((keyName, i) => (
                                            <li key={i}>
                                                <span className="input-label"><b>{keyName}</b> {delivery_address[keyName]}</span>
                                            </li>
                                        ))}
                                    </Card>
                                </Col>
                                <Col flex={1}>
                                    <Card title="Sender address">
                                        {Object.keys(sender_address).map((keyName, i) => (
                                            <li className="travelcompany-input" key={i}>
                                                <span className="input-label"><b>{keyName}</b> {sender_address[keyName]}</span>
                                            </li>
                                        ))}
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </>
            )
        }
        else { //IF NO DATA COULD BE LOADED
            return <>
                <Empty />
                <p>{JSON.stringify(readerror.message)}</p>
            </>;
        }
    }
}

export const Archive = withRouter(ArchiveComponent);
