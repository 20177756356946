import React from 'react'
import { Button, Avatar } from 'antd';
import { loggedUserName, navigate } from '../services/app';
import { Routes } from '../constants';

export class Profile extends React.Component {
    render() {
        const user = loggedUserName();
        return <Button className='btn-header' id="loginbutton" onClick={() => navigate(Routes.Login)}>
            {user ?
                <>
                    <Avatar className='avatar-btn' size={20}>{user.substr(-20, 2).toUpperCase()}</Avatar>
                    <span>Hello, {user}</span>
                </>
                :
                <span>Login</span>
            }
        </Button>;
    }
}
