import React, { useState } from "react";

export const AppContext = React.createContext({});

export function AppContextProvider(props) {

    const [refresh, setRefresh] = useState(true);

    const appContextValue = {
        reRenderApp: () => {
            setRefresh(!refresh)
        },
    }

    return <AppContext.Provider value={appContextValue}>
        {props.children}
    </AppContext.Provider>;
}
