import { isLoggedIn, loggedUserName } from '../services/app';

export function Landing() {

  return <div className="Landing">
    {
      isLoggedIn() ?
        <>
          <h2>Hello! Welcome {loggedUserName()}</h2>
          <h1>To handle your order, just choose the step in the sidebar.</h1>
        </>
        :
        <>
          <h2>Hello!</h2>
          <h1>To start using our system, you need to login with your account.</h1>
        </>
    }
  </div>;
}

