import {useContext} from 'react';
import {useHistory} from 'react-router-dom';
import Cookies from 'universal-cookie';

import {AppContext} from '../app.context';

const cookies = new Cookies();

let history;
let app;

export function useInitHistory() {
    history = useHistory();
    app = useContext(AppContext);
}

export function navigate(route, reRender = false) {
    history.push(route);
    if (reRender) app.reRenderApp();
}

export function isLoggedIn() {
    return cookies.get('JWT') ? true : false;
}

export function loggedUserName() {
    return cookies.get('user');
}

export function getCountryCode(countryCode) {
    if (countryCode === 'ES') return 'ES'
    return cookies.get('site');
}

export function productsAllowed() {
    return cookies.get('productsAllowed') || [];
}

export function userHasAccess() {
    if (!cookies.get('user')) return false;
    const productsAllowed = cookies.get("productsAllowed") || [];
    const checkingPermission = productsAllowed.some((productsAllowed) => productsAllowed === "New" || productsAllowed === "all");
    return checkingPermission;
}

export function isAdm(country) {
    if (!cookies.get('user')) return false;
    const productsAllowed = cookies.get("productsAllowed") || [];
    if (country) {
        const isBE = country === 'BE'
        const isES = country === 'ES'
        if (isBE) return productsAllowed.some(productsAllowed => productsAllowed === 'AdmInkoprintBE' || productsAllowed === 'all');
        if (isES) return productsAllowed.some(productsAllowed => productsAllowed === 'AdmInkoprintES' || productsAllowed === 'all');
        return false;
    } else {
        const checkingADM = productsAllowed.some(productsAllowed => productsAllowed === 'all');
        return checkingADM;
    }
}

export function isBelgium(countryCode) {
    if (countryCode === 'ES') return false
    return getCountryCode() === "BE";
}

export function isSpain() {
    return getCountryCode() === "ES";
}

export function getParam(name, url = window.location.href) {
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}