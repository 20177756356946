import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { Row, Col, Button, DatePicker, Input, Alert, Timeline, Divider, Card, Typography, Tabs, Spin } from "antd";

import { ChangeStatusButton } from "../components";
import { getCountryCode, getOrderById, } from "../services";

const { TextArea } = Input;
const { TabPane } = Tabs;
const { Title } = Typography;

class ToShipComponent extends React.Component {
  constructor(props) {
    super(props);
    this.changeDeliveryDate = this.changeDeliveryDate.bind(this);

    this.state = {
      strapiId: null,
      order: [],
      delivery_address: {},
      sender_address: {},
      calculation: [],
      description: {},
      uploads: [],
      orderID: null,
      readerror: [],
      calculatedPrice: null,
      newDateYMD: "",
      newDateDMY: "",
      message: "",
      button: false,
      renderAlertDate: false,
      renderAlertMessage: false,
      loading: true,
    };
  }

  componentDidMount = async () => {
    const id = this.props.match.params.id;
    const country = this.props.match.params.country === 'ES'? 'ES': 'BE';
    this.setState({ orderID: id });

    try {
      const response = await getOrderById(id, country);
      const order = response.data[0];

      this.setState({
        strapiId: order.id,
        order: order,
        delivery_address: order.receiver,
        sender_address: order.sender,
        calculation: order.fullorder,
        description: order.description_long,
        uploads: order.uploads,
        loading: false,
      });

      // SET CALCULATIONS IF MORE THAN ONE
      if (order.calculation) {
        if (typeof order.calculation["aantal vel papier"].eerste === "object") {
          this.setState({ calculation: order.calculation["aantal vel papier"], });
        }
        else {
          this.setState({ calculation: order.calculation });
        }
      }
    }
    catch (error) {
      this.setState({ readerror: error });
    }
  }

  changeDeliveryDate(date) {
    var day = date?.date() > 9 ? date?.date() : "0" + date?.date();

    var month = date?.month() + 1;
    if (month < 10) {
      month = "0" + month;
    }

    const year = date?.year();

    //CREATING NEW DELIVERY DATE FOR INKOPRINT.BE AND SET IN THE VARIABLE
    var newDateYMD = year + "-" + month + "-" + day;

    //CREATING NEW DELIVERY DATE FOR INKOSMART.BE AND SET IN THE VARIABLE
    var newDateDMY = day + "-" + month + "-" + year;

    this.setState({
      newDateYMD: newDateYMD,
      newDateDMY: newDateDMY,
      renderAlertDate: false,
    });

    if (this.state.message.length > 9) {
      this.setState({ button: true })
    }
  }

  // Can not select days before today and today
  disabledDate(current) {
    return current && current < moment().endOf("day");
  }

  nextStatus = () => {
    const country = this.props.match.params.country === 'ES'? 'ES': 'BE';
    const data = {
      Status: "Shipping",
      order_id: this.state.orderID,
      order_status: "IS_ready_for_shipment",
      Site: getCountryCode(country),
      buttonText: "to shipping",
      strapiID: this.state.strapiId,
      method: "put",
      nextStep: "shipping",
      type: "primary"
    };
    return data;
  };

  ShippingStatus = () => {
    const country = this.props.match.params.country === 'ES'? 'ES': 'BE';
    const data = {
      Status: "InProduction",
      trigger: "changeshipdate",
      order_id: this.state.orderID,
      order_status: "IS_in_production",
      order_new_date: this.state.newDateDMY,
      order_new_dateYMD: this.state.newDateYMD,
      order_new_date_message: this.state.message,
      Site: getCountryCode(country),
      buttonText: "Change shipping date",
      strapiID: this.state.strapiId,
      method: "put",
      nextStep: "production",
      type: "danger",
    };
    return data;
  };

  render() {
    const { order, orderID, loading, sender_address, delivery_address, calculation, description, uploads, } = this.state;

    if (loading) return <Spin />;

    if (!orderID) return <></>;

    var today = new Date();
    var parts = order.deliverydate.split("-");
    var lastDay = new Date(parts[2], parts[1] - 1, parts[0]);
    var remainingDays = Math.ceil((lastDay - today) / (1000 * 3600 * 24));

    //SETTING CALENDAR IN EUROPE FORMAT
    const dateFormat = "DD/MM/YYYY";

    return (
      <>
        <Row>
          <Col flex={10}>
            <Title>
              {orderID} - {order.description}
            </Title>
          </Col>
          <Col flex={1}>
            <ChangeStatusButton statusData={this.nextStatus()} />
          </Col>
        </Row>

        <Tabs defaultActiveKey="1" onChange={this.callBack}>
          <TabPane tab="Summary" key="1">
            <Title level={2}>Summary</Title>

            <Row>
              <Col flex={1}>
                <Divider orientation="left">Date information</Divider>
                <br />
                <Timeline>
                  <Timeline.Item>
                    Order date was {order.orderdate}
                  </Timeline.Item>
                  <Timeline.Item>
                    Shipping date {order.deliverydate}
                  </Timeline.Item>
                </Timeline>
                <br />

                <Divider orientation="left">Price information</Divider>

                <p>
                  <b>Price: </b>
                  {order.price}
                </p>

                <br />
                <Divider orientation="left">Shipping information</Divider>
                <p>
                  <b>Remaining days to complete: </b>
                  {remainingDays}
                </p>
              </Col>
              <Col flex={1}>
                <Card title="Product information">
                  <h3>{order.amount}x</h3>
                  {Object.keys(description ? description : "").map(
                    (keyName, i) => (
                      <li key={i}>
                        <span className="input-label">
                          <b>{keyName}</b> {description[keyName]}
                        </span>
                      </li>
                    )
                  )}
                </Card>
                <br />
                <Card title="Change shipping date">
                  <h3>Current shipping date: {order.deliverydate}</h3>
                  <DatePicker
                    onChange={this.changeDeliveryDate}
                    disabledDate={this.disabledDate}
                    format={dateFormat}
                  />
                  <br />
                  <br />
                  Reason: <br />
                  <TextArea
                    placeholder="To reject the order, the reason must be longer than 10 characters."
                    id="textReason"
                    allowClear
                    showCount
                    onChange={(event) => {
                      const message = event.target.value;
                      this.setState({ message: message });

                      if (message.length > 9 && this.state.newDateDMY.length !== 10) {
                        this.setState({
                          button: false,
                          renderAlertDate: true
                        });
                      } else if (message.length < 9 && this.state.newDateDMY.length !== 10) {
                        this.setState({
                          button: false,
                          renderAlertDate: true
                        })
                      } else if (message.length > 9 && this.state.newDateDMY.length === 10) {
                        this.setState({
                          button: true,
                          renderAlertMessage: false,
                        })
                      } else if (message.length < 9 && this.state.newDateDMY.length === 10) {
                        this.setState({
                          button: false,
                          renderAlertMessage: true,
                        })
                      }
                    }}
                    rows={4}
                  />
                  {this.state.button ?
                    <ChangeStatusButton statusData={this.ShippingStatus()} />
                    :
                    <Button disabled danger> Change shipping date</Button>
                  }
                  <p></p>
                  {/* WARNING FOR LESS THAN 10 CHARACTERS*/}
                  {this.state.renderAlertMessage ? (
                    <Alert
                      message="The Reason must be longer than 10 characters"
                      type="error"
                      closable
                      onClose={() =>
                        this.setState({ renderAlertMessage: false })
                      }
                    />
                  ) : (
                    ""
                  )}
                  {/* WARNING FOR NEW DATE NOT SELECTED*/}
                  {this.state.renderAlertDate ? (
                    <Alert
                      message="You forgot to fill in the new date"
                      type="error"
                      closable
                      onClose={() =>
                        this.setState({ renderAlertDate: false })
                      }
                    />
                  ) : (
                    ""
                  )}
                </Card>
                <br />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Print information" key="2">
            <Title level={2}>Print information</Title>
            <Row>
              <Col flex={1}>
                <Card title="Jobsheet and PDF">
                  <a href={order.jobsheet}>Jobsheet</a>
                  <p>
                    {uploads
                      ? uploads.map((upload) =>
                        Object.keys(
                          this.state.upload && upload ? upload : ""
                        ).map((keyName, index) => (
                          <Button
                            key={index}
                            target="_blank"
                            href={upload[keyName]}
                          >
                            {keyName}
                          </Button>
                        ))
                      )
                      : ""}
                  </p>
                </Card>
                <Card title="Calculation outcome">
                  <ShowCalculations calc={calculation} />
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Delivery" key="3">
            <Title level={2}>Delivery information</Title>

            <Row>
              <Col flex={1}>
                <Card title="Delivery address">
                  {Object.keys(delivery_address ? delivery_address : "").map(
                    (keyName, i) => (
                      <li key={i}>
                        <span className="input-label">
                          <b>{keyName}</b> {delivery_address[keyName]}
                        </span>
                      </li>
                    )
                  )}
                </Card>
              </Col>
              <Col flex={1}>
                <Card title="Sender address">
                  {Object.keys(sender_address ? delivery_address : "").map(
                    (keyName, i) => (
                      <li className="travelcompany-input" key={i}>
                        <span className="input-label">
                          <b>{keyName}</b> {sender_address[keyName]}
                        </span>
                      </li>
                    )
                  )}
                </Card>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </>
    );
  }
}

export const ToShip = withRouter(ToShipComponent);

class ShowCalculations extends React.Component {
  ShowImpositionInformation(element, index) {
    switch (index) {
      case 0:
        return element + " pages";
      case 1:
        return element + " plates";
      case 2:
        return element + " sheets";
      case 3:
        return element + " sheets inset";
      case 4:
        return element + "x";
      case 5:
        return element;
      default:
        return;
    }
  }
  GetKeyInformation(type, index) {
    if (type[1] === "digi") {
      var objEerste = [
        {
          key: "1",
          Price: type[0],
          Printer: type[1],
          CostClick: type[2],
          RectoVerso: type[3],
          CostPaper: Math.ceil(type[4] * 100) / 100,
          Sheetamount: type[5],
          Impressionspersheet: type[6],
          CostFinish: type[7],
          PriceDigitalPaper: type[8],
          Weight: type[9],
          Kind: type[11] === 1 ? "Cover" : "Insert",
        },
      ];
      return objEerste;
    }
    if (type[1] === "CX104") {
      var objTweede = [
        {
          key: "2",
          Price: type[0],
          Printer: type[1],
          Ink: type[2],
          CTP: type[5],
          Stelkost: type[6],
          Operating: type[7],
          Finishing: type[8],
          Weight: type[9],
          ImpositionScheme: type[10][0].map((element, index) => {
            ///// HIER AAN TE PASSEN VOOR MEERDERE LIJNEN IMPOSITIESCHEMA
            return this.ShowImpositionInformation(element, index);
          }),
          PaperSize: type[11],
          Kind: type[17] === 1 ? "Cover" : "Insert",
        },
      ];
      return objTweede;
    } else return type;
  }
  render() {
    var eerste = [];
    var tweede = [];
    var eentje = [];
    if (typeof this.props.calc === "object") {
      eerste = this.props.calc.eerste;
      tweede = this.props.calc.tweede;
      eentje = this.props.calc;
    }

    if (eerste !== undefined) {
      return (
        <>
          <pre>{JSON.stringify(this.GetKeyInformation(eerste), null, 2)}</pre>
          <pre>{JSON.stringify(this.GetKeyInformation(tweede), null, 2)}</pre>
          <hr />
          {JSON.stringify(eentje)}
          <hr />
        </>
      );
    }
    if (eentje) {
      return (
        <>
          <pre>{JSON.stringify(this.GetKeyInformation(eentje), null, 2)}</pre>
        </>
      );
    } else {
      return <pre>{JSON.stringify(eentje)}</pre>;
    }
  }
}
