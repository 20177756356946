import React from 'react';
import { Button } from 'antd';
import { downloadLabelsTransSmart, getOrderById } from '../services';

export class GetLabels extends React.Component {
    downloadLabels = async () => {
        let response = await getOrderById(this.props.reference, this.props.country);
        const reference = response.data[0].TranssmartOptions.reference;
        
        response = await downloadLabelsTransSmart(reference);
        const result = response;
        var packageDocs = result[0].packageDocs;
        
        packageDocs.forEach(element => {
            var pdf = element.data;
            const linkSource = `data:application/pdf;base64,${pdf}`;
            const downloadLink = document.createElement("a");
            const fileName = "label" + reference + ".pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            downloadLink.remove();
        });
    }

    render = () => <Button type="primary" onClick={() => this.downloadLabels()}>Download labels</Button>
}
