import React from "react";
import { Button, Card } from "antd";

export class DownloadableFiles extends React.Component {
    Files() {

        let files = this.props.order.uploads;
        if (files.length === 0) {
            return <p>Files not uploaded yet, waiting for customer!</p>
        }
        return files.map((element, index) => {
            let key = Object.keys(element);
            console.log(element[key]);
            if (element[key] === undefined || element[key] === null) {
                return (
                    <p>Files not uploaded yet, waiting for customer!</p>
                );
            } else {
                return <Button type="secondary" key={index} target="_blank" href={element[key]}>PDF {index + 1}</Button>
            }

        })
    }
    render() {
        this.Files();
        const order = this.props.order;
        console.log(order);
        return (
            <Card>
                <h3>Download jobsheet</h3>
                <Button type="primary" href={order.jobsheet} target="_blank">Job sheet</Button>
                <br /><br />
                <h3>Download PDFs</h3>
                {this.Files()}
            </Card>
        )
    }
}
